import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const goldenAmiPlugin = createPlugin({
  id: 'golden-ami',
  routes: {
    root: rootRouteRef,
  },
});

export const GoldenAmiPage = goldenAmiPlugin.provide(
  createRoutableExtension({
    name: 'GoldenAmiPage',
    component: () =>
      import('./components/GoldenAmiPage').then(m => m.GoldenAmiPage),
    mountPoint: rootRouteRef,
  }),
);
