import React from 'react';
import {ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef,} from '@backstage/integration-react';
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
    analyticsApiRef,
    identityApiRef,
    discoveryApiRef,
    githubAuthApiRef,
    oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { GoogleAnalytics4 } from '@backstage/plugin-analytics-module-ga4';
import { GithubAuth } from '@backstage/core-app-api';
import { apiDocsConfigRef, defaultDefinitionWidgets, ApiDefinitionWidget } from '@backstage/plugin-api-docs';
import { RedocStandalone } from 'redoc';
import { useTheme } from '@material-ui/core/styles';
import type { ApiEntity } from '@backstage/catalog-model';

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: apiDocsConfigRef,
        deps: {},
        factory: () => {
          const definitionWidgets = defaultDefinitionWidgets();
          const theme = useTheme();
          
          const redocTheme = {
            colors: {
              primary: {
                main: theme.palette.type === 'dark'
                      ? theme.palette.primary.dark 
                      : theme.palette.primary.light
              },
              text: {
                primary: theme.palette.type === 'dark'
                      ? theme.palette.primary.dark 
                      : theme.palette.primary.light,
                secondary:
                    theme.palette.type === 'dark'
                      ? theme.palette.secondary.dark 
                      : theme.palette.secondary.light
              }
            },
          };

          return {
            getApiDefinitionWidget: (apiEntity: ApiEntity) => {
                if (apiEntity.spec.type === 'openapi-redoc') {
                    return {
                        type: 'openapi-redoc',
                        title: 'OpenAPI Redoc',
                        component: (definition: any) => 
                            <RedocStandalone 
                                spec={JSON.parse(definition)} 
                                options={{
                                    showExtensions: true,
                                    nativeScrollbars: true,
                                    theme: redocTheme
                                }}
                            />
                    } as ApiDefinitionWidget;
                }
            return definitionWidgets.find(d => d.type === apiEntity.spec.type);
            }
          };
        },
    }),
    // Instantiate and register the GA Analytics API Implementation.
    createApiFactory({
        api: analyticsApiRef,
        deps: { configApi: configApiRef, identityApi: identityApiRef },
        factory: ({ configApi, identityApi }) =>
          GoogleAnalytics4.fromConfig(configApi, {
            identityApi,
        }),
    }),
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory({
        api: githubAuthApiRef,
        deps: {
            configApi: configApiRef,
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
        },
        factory: ({discoveryApi, oauthRequestApi, configApi}) =>
            GithubAuth.create({
                discoveryApi,
                oauthRequestApi,
                defaultScopes: ['read:user'],
                environment: configApi.getString('auth.environment'),
            }),
    })
];


